/* eslint-disable no-nested-ternary */
import React from 'react'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../components/shared/landingPages/layoutComponents'
import Header from '../components/shared/landingPages/header'
import TopFeatures from '../components/shared/landingPages/topFeatures'
import PriceBlock from '../components/shared/landingPages/priceBlock'
import Functioning from '../components/shared/landingPages/functioning'
import DetailedDescription from '../components/shared/landingPages/detailedDescription'
import FAQ from '../components/shared/landingPages/faq'
import WhyTellowCompleetFunction from '../components/shared/landingPages/whytellowcompleet'
import topFeaturesPng from '../images/inkomstenbelasting/topfeatures-2024.png'
import headerImage from '../images/inkomstenbelasting/headerImageFree2024.png'
import XIcon from '../components/UI/Icons/xIcon'
import QuestionIcon from '../components/UI/Icons/questionIcon'
import TickIcon from '../components/UI/Icons/tickIcon'
import whytellow from '../images/whytellowcompleet/whytellowcompleet.png'
import VatDeclaration from '../images/lottieAnimations/VatDeclarationAnimation'
import Testimonial1 from '../images/Review/testimonial1.png'
import Testimonials from '../components/pages/reviews/testimonialsContainer'
import * as Banking from '../components/pages/banking/styled'
import { RegisterButton } from '../components/UI'

const url = 'https://tellow.typeform.com/to/xI2OBJdH'

const headerData = () => ({
    announcer: 'Tijdelijke zakelijke aanbieding',
    title: `Inkomstenbelasting laten doen én op tijd indienen`,
    points: ['+ Krijg expert tips & nauwkeurige ondersteuning', '+ Maximaliseer teruggaves en voorkom fouten', '+ Bespaar tijd en geld met Tellow Compleet'],
    textCTA: 'Claim de deal',
    information: 'Beperkt aantal plaatsen beschikbaar',
    // phone: support.phoneNumber,
    ...(url ? { url } : {}),
})

const descriptionData = () => ({
    title: 'Zelfverzekerd over je boekhouding',
    description:
        'Meld je aan voor Tellow Compleet en krijg een heel jaar toegang tot onze gebruiksvriendelijk boekhoudsoftware met handige tools. Factureren, offertes sturen en bonnetjes scannen doe je voortaan met de slimme app die automatisch je boekhouding doet.',
    secondaryTitle: 'Je boekhouding wordt een fluitje van een cent',
    secondaryDescription: 'Een erkende boekhouder stelt 4x per jaar de btw-aangifte en 1x per jaar de inkomstenbelasting voor je op. Ook voor je fiscale partner. *',
    // desktopImage: tellowApp,
    // mobileImage: headerPng,
    animation: <VatDeclaration />,
    imageAlt: 'Tellow mockup',
    textCTA: 'Upgraden naar Compleet',
    moneyBackGuarantee: `14 dagen 'niet goed, geld terug' garantie`,
    ...(url ? { url } : {}),
})

const priceBlockData = (price) => ({
    options: [
        {
            price: `Inkomstenbelasting 2023`,
            // disclaimer: 'eenmalig',
            // title: 'Inkomstenbelasting 2022',
            features: ['IB-aangifte 2023 volledig uitbesteden', 'Snelle afhandeling, we starten direct', 'Ervaren boekhouders met grote korting'],
            bottomPrice: {
                price: `Gratis`,
                priceDisclaimer: 'Je bespaart €599',
            },
        },
        {
            price: 'Boekhouding 2024',
            // disclaimer: 'per maand voor een jaar',
            // title: 'Boekhouding 2023',
            features: ['Je eigen boekhouder voor advies en vragen', 'Volledig ontzorgt, zowel zakelijk als privé', 'Controles op boekhouding, aangiftes en besparingen'],
            bottomPrice: {
                price: `€69,99`,
                priceType: 'per maand',
                priceDisclaimer: 'Compleet voor een jaar',
            },
        },
    ],
    // textCTA: 'Nu registeren',
    // information: 'Heb je vragen? Bel onze adviseurs direct.',
    // phone: support.phoneNumber,
    // ...(url ? { url } : {}),
})

const functioningData = () => ({
    title: 'Hoe werkt het?',
    subtitle:
        "Tellow biedt zzp'ers een unieke mogelijkheid om de inkomstenbelasting van 2022 volledig uit te besteden. Dit geeft je volledige zekerheid dat jouw inkomstenbelasting op tijd en op de juiste wijze wordt ingediend, inclusief alle heffingen en kortingen. De boekhouder doet jouw aangifte inkomstenbelasting en corrigeert die zo nodig.",
    textCTA: 'Meld je aan',
    // information: `Wil je een vraag stellen? Ons support team zit voor je klaar op werkdagen tussen ${support.opensAtTime} en ${support.closesAtTime}. Je kan ons bereiken op:`,
    // phone: support.phoneNumber,
    ...(url ? { url } : {}),
    points: [
        {
            num: '1',
            title: 'Meld je aan bij Tellow Compleet',
            body: 'Wij maken je wegwijs in het platform en helpen je bij het invoeren van je gegevens.',
        },
        {
            num: '2',
            title: 'Lever je gegevens aan bij de boekhouder',
            body: 'Dit gebeurt allemaal online, op een makkelijke en veilige manier.',
        },
        {
            num: '3',
            title: 'De boekhouder stelt de IB-aangifte voor je op',
            body: 'Onze boekhouders checken en corrigeren jouw gegevens volgens Belastingdienst-eisen.',
        },
        {
            num: '4',
            title: 'Vliegensvlug je aangifte indienen',
            body: 'Download je aangifte bestand en dien deze binnen enkele minuten in bij de Belastingdienst.',
        },
    ],
})

const TestimonialsData = () => ({
    testimonials: [
        {
            title: `Snel en goed persoonlijk contact`,
            text: `Alle bijzonderheden bij de inkomstenbelasting die gevonden worden door de boekhouder, worden duidelijk uitgelegd met de juiste hulp tools om alles in de juiste manier weg te zetten! Een uiterst snelle en prettige flexibele samenwerking!`,
            name: `Sophie`,
            position: `Business analyst`,
            img: Testimonial1,
        },
    ],
    textCTA: 'Neem Tellow Compleet',
    ...(url ? { url } : {}),
})

const whyTellowCompleetData = {
    features: [
        [
            {
                title: <li>BTW berekeningen</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <TickIcon />,
            },
            {
                title: <li>BTW aangifte indienen</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <TickIcon />,
            },
            {
                title: <li>Voorbereiding inkomstenbelasting (IB)</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <TickIcon />,
            },
            {
                title: <li>Indienen aangifte inkomstenbelasting (IB)</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <TickIcon />,
            },

            {
                title: <li>IB aangifte fiscale partner gratis</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <QuestionIcon />,
            },
        ],
        [
            {
                title: <li>Onbeperkt je boekhouder bellen voor vragen</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <QuestionIcon />,
            },

            {
                title: <li>Matching aan de boekhouder die het best bij je past</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
        [
            {
                title: <li>Administratie aanleveren en bewaren</li>,
                IconleftColumn: <XIcon />,
                IconrightColumn: <TickIcon />,
            },
        ],
        [
            {
                title: <li>Facturen opstellen en verzenden</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Urenregistratie en kilometer registratie</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Offertes opmaken en versturen</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Betalingsherinneringen versturen</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Betaalverzoek meesturen voor directe betaling</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
        [
            {
                title: <li>Real time inzicht in je financien </li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Zakelijke leningen</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Factuur financiering</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Bank transacties automatisch inladen</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
        [
            {
                title: <li>Native iOS en Android apps</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Webapp voor gebruik op computer</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
        [
            {
                title: <li>Persoonlijke advies</li>,
                IconleftColumn: <QuestionIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Bereikbaar via email, chat en telefoon ook in de avond</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
            {
                title: <li>Informatieve webinars</li>,
                IconleftColumn: <TickIcon />,
                IconrightColumn: <XIcon />,
            },
        ],
    ],
    maintitle: `Waarom Tellow Compleet?`,
    titleLeftColumn: `Tellow Compleet`,
    titleRightCoulmn: 'Traditionele Boekhouder',
    title: 'Verschil met een traditionele boekhouder?',
    text: 'Tellow Compleet is veel meer dan alleen een boekhouder. Je kunt namelijk altijd persoonlijk contact hebben (zonder extra kosten) en ongelimiteerd gebruik maken van de complete software van Tellow. Bekijk alle verschillen in de vergelijker.',
    whyTellowImage: whytellow,
    textCTA: `Vergelijk`,
}

const topFeaturesData = (price) => ({
    title: 'Alle voordelen',
    topFeaturesImage: topFeaturesPng,
    ...(url ? { url } : {}),
    points: [
        {
            subtitle: 'Aangifte inkomstenbelasting 2023',
            points: [
                {
                    title: `Je aangifte voor 2023 voor €${price || 299},-`,
                    body: 'Volledig uitbesteden en op tijd indienen zonder zorgen. Jij levert je spullen aan en onze professionals nemen je al het werk uit handen. Ook voor je fiscale partner.',
                },
                {
                    title: 'Maximaal voordeel halen',
                    body: 'Onze gespecialiseerde boekhouders houden rekening met alle gunstige aftrekposten en heffingskortingen, inclusief specifieke regels.',
                },
            ],
        },
        {
            subtitle: 'Boekhouding 2024',
            points: [
                {
                    title: 'Makkelijk uitgaven bijhouden',
                    body: 'Met onze apps scan, verwerk en sla je al je bonnen op, dus geen schoenendoos meer en je raakt ook niks kwijt.',
                },
                {
                    title: 'Facturen, offertes en herinneringen',
                    body: 'Verstuur facturen en offertes met je eigen logo, kleuren en gewenste lay-out rechtstreeks vanuit de app, en ontvang meldingen zodra ze zijn betaald of goedgekeurd.',
                },
                {
                    title: 'Automatisch aangifte doen',
                    body: '4x per jaar btw-aangifte en 1x per jaar je inkomstenbelasting, altijd gecheckt door onze ervaren boekhouders. Zo ben je nooit meer te laat en voorkom je boetes.',
                },
                {
                    title: 'Persoonlijke boekhouder',
                    body: 'Onze boekhouders staan voor je klaar, ook in de avond. Boekhouden en belastingen kunnen lastig zijn, maar we helpen je met liefde om het je zo makkelijk mogelijk te maken.',
                },
            ],
        },
    ],
    textCTA: 'Upgraden naar Compleet',
})

const faqData = (price) => ({
    title: 'Veelgestelde vragen',
    ...(url ? { url } : {}),
    faq: [
        {
            question: 'Wat kost het laten doen van inkomstenbelasting 2023?',
            answer: (
                <>
                    <p>
                        Neem jij Tellow Compleet? Dan kan je voor €{price || 299},- jouw inkomstenbelasting 2023 laten doen door onze boekhouder.
                        <br />
                        Dit houdt het volgende in:
                    </p>
                    <ul style={{ listStyle: 'inside', listStyleType: 'disc' }}>
                        <li>
                            Onze boekhouder doet jouw aangifte inkomstenbelasting
                            <ul style={{ listStyle: 'inside', listStyleType: 'circle' }}>
                                <li>Inclusief winst uit onderneming</li>
                                <li>Inclusief eigen huis het hele jaar in bezit</li>
                                <li>Inclusief evt. loondienstinkomen</li>
                                <li>Inclusief de aftrekposten: ziekteposten, studiekosten, giften</li>
                                <li>Inclusief box 3 op basis van vooraf ingevulde gegevens</li>
                            </ul>
                        </li>
                        <li>
                            Onze boekhouder stelt een jaarrekening op
                            <ul style={{ listStyle: 'inside', listStyleType: 'circle' }}>
                                <li>Inclusief auto op de zaak</li>
                            </ul>
                        </li>
                        <li>Eventueel aanvragen van uitstel</li>
                    </ul>
                    <p>
                        De €{price || 299},- is op basis van een normale Nederlandse winst aangifte. Heb je uitzonderingen op het bovenstaande? Geen probleem. Tellow kan alles uit
                        handen nemen. Wij hanteren hier een vaste prijslijst voor. Lees meer hierover onder:{' '}
                        <span style={{ fontWeight: '600' }}>Wat doet Tellow met uitzonderingen in mijn aangifte inkomstenbelasting?</span>
                    </p>
                </>
            ),
        },
        {
            question: 'Doet Tellow ook inkomstenbelasting 2024?',
            answer: <p>Ja! Met het Tellow Compleet abonnement krijg je hulp met de aangifte inkomstenbelasting over 2024 volgend jaar. Zonder bijkomende kosten.</p>,
        },
        {
            question: 'Wanneer moet ik inkomstenbelasting doen?',
            answer: (
                <>
                    <p>De aangifte inkomstenbelasting voor 2023 kun je doen vanaf 1 maart 2024 en moet vóór 1 mei binnen zijn bij de Belastingdienst.</p>
                    <p>
                        Heb je een aangiftebrief gehad en ben je te laat met aangifte doen? Dan riskeer je een boete. Wij raden het dan ook aan om altijd op tijd en correct je
                        aangifte te doen.
                    </p>
                </>
            ),
        },
        {
            question: "Hoe werkt inkomstenbelasting aangifte voor zzp'ers?",
            answer: (
                <>
                    <p>
                        Als zelfstandige zonder personeel betaal je (afhankelijk van het aantal gewerkte uren en aftrekposten) dit jaar geen belasting over een winst tot €32.193,-.
                        Let op, dit betekent niet dat je geen aangifte hoeft te doen!
                    </p>
                    <p>
                        Als zzp'er kun je gebruik maken van aftrekposten, zoals de zelfstandigenaftrek, startersaftrek, oudedagreserve, MKB winstvrijstelling en verschillende
                        heffingskortingen. Met Tellow Compleet checkt onze boekhouder jouw administratie en weet precies op welke kortingen jij recht hebt. Dat scheelt dus geld.
                    </p>
                </>
            ),
        },
        {
            question: 'Doet Tellow ook de aangifte van mijn fiscaal partner?',
            answer: (
                <p>
                    Tellow doet de aangifte van jou fiscale partner wanneer deze in loondienst is. Is jouw fiscale partner ondernemer of zzp’er dan is dit een uitzondering en
                    zullen er extra kosten in rekening worden gebracht.
                </p>
            ),
        },
        {
            question: 'Ik heb vermogen in box 3, geldt dit als een uitzondering?',
            answer: (
                <p>
                    Vooraf ingevuld vermogen binnen box 3 wordt geheel verzorgd door de boekhouder. Over het vermogen buiten de vooraf ingevulde gegevens worden door de boekhouder
                    extra kosten in rekening gebracht. Zie onze prijslijst voor alle uitzonderingen.
                </p>
            ),
        },
        {
            question: 'Ik heb dit jaar een huis gekocht, geldt dit als een uitzondering?',
            answer: <p>Als jij een huis hebt gekocht in 2023 dan wordt dit door de boekhouder gezien als een uitzondering. Er worden extra kosten in rekening gebracht.</p>,
        },
        {
            question: 'Wat doet Tellow met uitzonderingen in mijn aangifte inkomstenbelasting?',
            answer: (
                <>
                    <p>Wanneer je uitzonderingen hebt op de normale aangifte inkomstenbelasting hanteren wij een vaste prijslijst. Check hieronder de uitzonderingen:</p>
                    <ul style={{ listStyle: 'inside', listStyleType: 'disc' }}>
                        <li>Administratie moet nog geboekt worden € 150</li>
                        <li>BTW suppletie €50</li>
                        <li>Box 3 aangifte buiten vooraf ingevulde gegevens €100</li>
                        <li>M/C-biljet (buitenland) €100</li>
                        <li>Huis gekocht/verkocht €50</li>
                        <li>Familiare lening €50</li>
                        <li>Lijfrente €50</li>
                    </ul>
                </>
            ),
        },
        {
            question: 'Hoe zit het met mijn recht op restitutie binnen 14 dagen als ik Tellow Compleet gebruik?',
            answer: (
                <>
                    <p>Door gebruik te maken van één of meer services of dienstverlening van Tellow Compleet, vervalt de mogelijkheid tot restitutie binnen 14 dagen.</p>
                    <p>
                        Dit komt omdat het gebruik van de service wordt beschouwd als het accepteren van de voorwaarden en het starten van de dienstverlening, waardoor de
                        mogelijkheid tot terugbetaling vervalt.
                    </p>
                </>
            ),
        },
    ],
})

const InkomstenbelastingDeal = () => (
    <Layout noMenuBar>
        <Meta
            path="/inkomstenbelasting-deal"
            title="Inkomstenbelasting | Tellow.nl"
            keywords={['belastingaangifte', 'aftrekposten', 'eenmanszaak', 'freelancer', 'ondernemer']}
            description="Wij dienen aangifte inkomstenbelasting voor 2023 automatisch in ✅ Voor zzp'ers & eenmanszaken ✅ 100.000+ ondernemers gingen je al voor ✅ Bekijk de deal!"
            indexed="noindex"
            name="robots"
        />

        <Header
            data={headerData()}
            image={headerImage}
            purpleBackground
            greenAnnouncer
            bottomImage
            showPhoneButton
            buttonContainerGrid
            reverseButtonGrid
            phoneButtonWhiteBorder
            priceDisclaimerWhite
            lessBottomMarginOnMobile
        />

        <RelativeContainer>
            <ComponentContainer>
                <DetailedDescription data={descriptionData()} smallButtonMargins />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <PriceBlock data={priceBlockData()} hasPurpleBackground />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Functioning data={functioningData()} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <Testimonials data={TestimonialsData()} useRegisterButton />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <WhyTellowCompleetFunction data={whyTellowCompleetData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopFeatures data={topFeaturesData()} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <FAQ data={faqData()} />
            </ComponentContainer>
        </RelativeContainer>

        <Banking.ColouredBackground purple>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125} padding={6}>
                    <Banking.CTAWrapper>
                        <Banking.H2>Maximaliseer teruggaves en voorkom fouten</Banking.H2>
                        <p style={{ maxWidth: '44ch', fontSize: 'clamp(1rem, -0.375rem + 5.7778vw, 1.5rem)', fontWeight: 300 }}>
                            Laat je inkomstenbelasting doen door <br /> een erkende boekhouder.
                        </p>
                        <RegisterButton agerasGreen big url={url} style={{ textAlign: 'center' }}>
                            Upgraden naar Compleet <span className="arrow">{'->'}</span>
                        </RegisterButton>
                    </Banking.CTAWrapper>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>
    </Layout>
)

export default InkomstenbelastingDeal
